import React from 'react'
import {
	withRouter
} from 'react-router-dom'
import { 
	List, 
	ListItem, 
	ListItemIcon,
	ListItemText 
} from '@material-ui/core'
import { mainmenu } from '../../constants/menu'



// main menu 
class Mainmenu extends React.Component {

	render(){
		return(
			<List component='nav'>
				{
					mainmenu.map((m, id) => {
						return(
							<ListItem
								button
								key={id}
								onClick={() => {
									this.props.close()
									this.props.history.push(m.link)
								}}
							>
								<ListItemIcon>
									{m.icon}
								</ListItemIcon>
								<ListItemText>
									{m.name}
								</ListItemText>
							</ListItem>
						)
					})
				}
			</List>
		)
	}
}

export default withRouter(Mainmenu)


