import React from 'react'
import { withRouter } from 'react-router-dom'
import MUIDataTable from "mui-datatables"
import {
	Button, 
	CircularProgress,
	IconButton,
} from '@material-ui/core'
import {
	Delete,
	Edit,  
	Check,
	Close
} from '@material-ui/icons'
import { table_options } from '../../constants/table'
import UpControl from './UpControl'
import { UniConfirm } from './../ui'
import moment from 'moment'
import { 
	get_query,
	delete_query
} from '../../functions'


// itemsols for branchsols
class Itemsols extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			data: []
		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(np.id && this.props.id !== np.id){
			this.getData(np.id)
		}
	}

	getData(id){
		get_query(`/api/itemsols/${id}`)
		.then((res) => {
			this.setState({ loader: false, data: res })
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	//  удалить данные
	deleteRow(id){
		this.setState({ loader: true  })
		delete_query(`/api/itemsols/${id}`)
		.then(() => {
			this.getData(this.props.id)
		})
		.catch((err) => {
			this.setState({ loader: false  })
		})
	}

	confCols(){
		let cols = [{
			name: "name",
			label: "Решение",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "updated_at",
			label: "Обновлено", 
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? (
								<span>
									{moment(val, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')} 
								</span>
							) : false
						}
					</>
				)
			}
		},{
			name: "viewrecord",
			label: "Показывать",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? 
								<Check style={{ color: 'green' }}/> :
								<Close style={{ color: 'red' }}/>
								
						}
					</>
				)
			}
		},{ 
			name: 'id',
			label: 'Действия',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<div style={{ width: 180 }}>
						<IconButton
							color='primary'
							onClick={() => this.props.history.push(`/itemsols/edit/${this.props.id}/${val}`)}
						>
							<Edit />
						</IconButton>
						<UniConfirm
							action={() => this.deleteRow(val)}
						>
							<IconButton
								color='secondary'
							>
								<Delete />
							</IconButton>
						</UniConfirm>
					</div>
				)
			}
		}]
		return cols
	}

	render(){
		return(
			<div>
				<UpControl>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.history.push(`/itemsols/new/${this.props.id}`)}
					>
						Добавить решение в категорию
					</Button>
				</UpControl>
				<MUIDataTable
					title={this.state.loader ? <CircularProgress /> : "Решения категории Zig-Zag"}
					data={this.state.data}
					columns={this.confCols()}
					options={table_options}
				/>
			</div>
		)
	}
}

export default withRouter(Itemsols)


