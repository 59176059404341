import React from 'react'
import Appbar from '../../containers/main/Appbar'


// Layout cmponent
export default class Layout extends React.Component {
	render(){
		return(
			<>
				<Appbar />
				<div className='container'>
					{this.props.children}
				</div>
			</>
		)
	}
}


