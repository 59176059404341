import { 
    combineReducers, 
    createStore 
} from "redux"
import { user_reducer } from './user_reducer'


const store = createStore(
    combineReducers({
        user_reducer,
    }), 
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;