import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button,
	Typography,
	FormControl,
	TextField,
	TextareaAutosize,
	FormControlLabel,
	Switch,
	Grid
} from '@material-ui/core'
import ImageLoader from './../../containers/modules/ImageLoader'
import DownControl from '../../containers/modules/DownControl'
import { get_query, post_query } from './../../functions'
import Itemsols from '../../containers/modules/Itemsols'

const initState = {
	name: '',
	viewrecord: false,
	image: '',
	description: ''
}

// new blog record
class BranchsolsEdit extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
		this.zeditor = React.createRef()
	}

	UNSAFE_componentWillMount(){
		this.setState(Object.assign({}, this.state, initState))
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		let id = this.props.match.params.id
		get_query(`/api/branchsols/${id}`)
		.then((res) => {
			this.setState({
				id: res.id,
				name: res.name,
				image: res.image,
				viewrecord: res.viewrecord,
				description: res.description,
				loader: false
			})
		})
		.catch((err) => {
			this.setState({ loader: true })
		})
	}

	// save record data
	saveData(){
		this.setState({ loader: true })
		let data = {
			id: this.state.id,
			viewrecord: this.state.viewrecord,
			name: this.state.name,
			image: this.state.image,
			description: this.state.description
		}
		post_query('/api/branchsols/update', data)
		.then((res) => {
			this.props.history.push(`/branchsols`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Редактировать категорию решений
				</Typography>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
						<div className='div-flex-row'>
							<div
								style={{ padding: 16 }}
							>
								<ImageLoader 
									width={300}
									height={300}
									img={this.state.image}
									upload={(l) => this.setState({ image: l })}
								/>
							</div>
							<div className='fields'>
								<div>
									<FormControl
										className='form-item'
									>
										<TextField 
											inputProps={{style:{ width: 300 }}}
											label='Категория'
											value={this.state.name}
											onChange={(ev) => this.setState({ name: ev.target.value })}
										/>
									</FormControl>
								</div>
								<div>
									<FormControlLabel
										value='start'
										control={
											<Switch 
												color="primary" 
												checked={this.state.viewrecord}
												onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
											/>
										}
										label={this.state.viewrecord ? 
											<p style={{width: 120}}>Показано</p> : 
											<p style={{width: 120}}>Скрыто</p>
										}
										labelPlacement="start"
									/>
								</div>
							</div>
						</div>
						<p>Краткое описание категории:</p>
						<TextareaAutosize
							placeholder='Краткое описание категории'
							rowsMin={5}
							style={{ width: 700 }}
							value={this.state.description}
							onChange={(ev) => this.setState({ description: ev.target.value })}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
						<Itemsols 
							id={this.state.id}
						/>
					</Grid>
				</Grid>
				<DownControl
					loader={this.state.loader}
				>
					<Button
						variant='contained'
						color='secondary'
						onClick={() => this.props.history.push('/branchsols')}
					>
						Отменить
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.saveData()}
					>
						Сохранить
					</Button>
				</DownControl>
			</>
		)
	}
}

export default withRouter(BranchsolsEdit)


