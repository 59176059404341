import React from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'


// детали страницы
export default class PagesDetail extends React.Component {

	render(){
		let sdt = this.props.created_at ? moment(this.props.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : false
		let udt = this.props.updated_at ? moment(this.props.updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : false
		return(
			<div>
				<Typography variant='h5'>
					Детали изменения страницы
				</Typography>
				<p><b>Создано:</b> {sdt}</p>
				<p><b>Изменено: </b>{udt} {this.props.lastuser}</p>
			</div>
		)
	}
}


