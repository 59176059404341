import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button,
	CircularProgress,
	Typography
} from '@material-ui/core'
import { get_query } from './../../functions'
import ImageLoader from '../../containers/modules/ImageLoader'


// view clients from part
class PartclientsView extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		let id = this.props.match.params.id
		get_query(`/api/clients/${id}`)
		.then((res) => {
			res.loader = false
			this.setState(Object.assign({}, this.state, res))
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<>
				<div>
					<Typography variant='h4'>
						Просмотр статьи в блог
					</Typography>
					<div 
						className='div-flex-row'
					>
						<div
							style={{
								padding: 16
							}}
						>
							<ImageLoader 
								width={380}
								height={200}
								img={this.state.img}
								hidebtn={true}
							/>
						</div>
						<div>
							<p>
								<b>Наименование:&emsp;</b>{this.state.name}
							</p>
							<p>
								<b>Страна:&emsp;</b>{this.state.country}
							</p>
							<p>
								<b>Категория:&emsp;</b>{this.state.category}
							</p>
							<p>
								<b>Ссылка:&emsp;</b>{this.state.link}
							</p>
							<p>
								<b>Краткое описание:&emsp;</b>{this.state.description}
							</p>
						</div>
					</div>
					
				</div>
				<div className='row-control-down'>
					<Button
						variant='contained'
						onClick={() => this.props.history.push('/partclients')}
					>
						Назад						
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.history.push(`/partclients/edit/${this.state.id}`)}
					>
						{
							this.state.loader ? (
								<CircularProgress color='inherit' size={24} />
							) : 'Редактировать'
						}
					</Button>
				</div>
			</>
		)
	}
}

export default withRouter(PartclientsView)

