// auth functions 
import axios from 'axios'
import store from './../reducers/store'
import * as user_act from './../actions/user_act'

//  sso query (check token)
export function sso_query(){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(`${process.env.REACT_APP_PROMO_API}/api/sso`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        })
        .then((res) => {
            store.dispatch(user_act.setUserInit({
                email: res.data.data.email,
                fullname: res.data.data.fullname,
                role: res.data.data.role,
                token: res.data.data.token
            }))
            resolve()
        })
        .catch((err) => {
            store.dispatch(user_act.setUserClear())
            reject()
        })
    })
}

// auth query
export function auth_query(email, password){
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_PROMO_API}/api/login`, {
            email: email,
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((res) => {
            store.dispatch(user_act.setUserInit({
                email: res.data.data.email,
                fullname: res.data.data.fullname,
                role: res.data.data.role,
                token: res.data.data.token,
            }))
            resolve()
        })
        .catch((err) => {
            store.dispatch(user_act.setUserClear())
            reject(err)
        })
    })
}