import React from 'react'
import {
	withRouter,
	Switch,
	Route
} from 'react-router-dom'
import PrivateRoute from './../single/PrivateRoute'
import Panel from './../panel/Panel'
import Login from '../single/Login'
import Exception from '../single/Exception'
import Users from '../users/Users'
import Pages from './../pages/Pages'
import PagesNew from './../pages/PagesNew'
import PagesEdit from './../pages/PagesEdit'
import Blog from './../blog/Blog'
import BlogNew from './../blog/BlogNew'
import BlogEdit from './../blog/BlogEdit'
import Opportunes from './../opportunes/Opportunes'
import OpportunesNew from './../opportunes/OpportunesNew'
import OpportunesEdit from './../opportunes/OpportunesEdit'
import Partclients from './../partclients/Partclients'
import PartclientsNew from './../partclients/PartclientsNew'
import PartclientsView from './../partclients/PartclientsView'
import PartclientsEdit from './../partclients/PartclientsEdit'
import Troubles from './../troubles/Troubles'
import TroublesNew from './../troubles/TroublesNew'
import TroublesEdit from './../troubles/TroublesEdit'
import Integra from './../integra/Integra'
import Branchsols from './../branchsols/Branchsols'
import BranchsolsNew from './../branchsols/BranchsolsNew'
import BranchsolsEdit from './../branchsols/BranchsolsEdit'
import ItemsolsNew from './../itemsols/ItemsolsNew'
import ItemsolsEdit from './../itemsols/ItemsolsEdit'



// Application routes
class Approutes extends React.Component {

	render(){
		return(
			<Switch>
				<PrivateRoute exact path='/'>
					<Panel />				
				</PrivateRoute>
				<PrivateRoute path='/integra'>
					<Integra />			
				</PrivateRoute>
				<PrivateRoute path='/users'>
					<Users />			
				</PrivateRoute>
				{/* pages */}
				<PrivateRoute exact path='/pages'>
					<Pages />			
				</PrivateRoute>
				<PrivateRoute path='/pages/new'>
					<PagesNew />			
				</PrivateRoute>
				<PrivateRoute path='/pages/edit/:id'>
					<PagesEdit />			
				</PrivateRoute>
				{/* blog */}
				<PrivateRoute exact path='/blog'>
					<Blog />			
				</PrivateRoute>
				<PrivateRoute path='/blog/new'>
					<BlogNew />			
				</PrivateRoute>
				<PrivateRoute path='/blog/edit/:id'>
					<BlogEdit />			
				</PrivateRoute>
				{/* partclients */}
				<PrivateRoute exact path='/partclients'>
					<Partclients />			
				</PrivateRoute>
				<PrivateRoute path='/partclients/new'>
					<PartclientsNew />			
				</PrivateRoute>
				<PrivateRoute path='/partclients/view/:id'>
					<PartclientsView />			
				</PrivateRoute>
				<PrivateRoute path='/partclients/edit/:id'>
					<PartclientsEdit />			
				</PrivateRoute>
				{/* troubles */}
				<PrivateRoute exact path='/troubles'>
					<Troubles />			
				</PrivateRoute>
				<PrivateRoute path='/troubles/new'>
					<TroublesNew />			
				</PrivateRoute>
				<PrivateRoute path='/troubles/edit/:id'>
					<TroublesEdit />			
				</PrivateRoute>
				{/* opportunities */}
				<PrivateRoute exact path='/opportunes'>
					<Opportunes />			
				</PrivateRoute>
				<PrivateRoute path='/opportunes/new'>
					<OpportunesNew />			
				</PrivateRoute>
				<PrivateRoute path='/opportunes/edit/:id'>
					<OpportunesEdit />			
				</PrivateRoute>
				{/* branch solutions */}
				<PrivateRoute exact path='/branchsols'>
					<Branchsols />			
				</PrivateRoute>
				<PrivateRoute path='/branchsols/new'>
					<BranchsolsNew />			
				</PrivateRoute>
				<PrivateRoute path='/branchsols/edit/:id'>
					<BranchsolsEdit />			
				</PrivateRoute>
				<PrivateRoute path='/itemsols/new/:bs'>
					<ItemsolsNew />			
				</PrivateRoute>
				<PrivateRoute path='/itemsols/edit/:bs/:id'>
					<ItemsolsEdit />			
				</PrivateRoute>
				{/* Login public */}
				<Route path='/login'>
					<Login />
				</Route>
				<Route>
					<Exception code={404} />
				</Route>
			</Switch>
		)
	}
}

export default withRouter(Approutes)
