import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button
} from '@material-ui/core'


// Part blog control panel
class PartclientsControl extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<div className='row-control'>
				<Button
					variant='contained'
					onClick={() => this.props.history.push('/partclients/new')}
				>
					Добавить клиента
				</Button>
			</div>
		)
	}
}

export default withRouter(PartclientsControl)


