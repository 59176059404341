import React from 'react'
import {
	AppBar,
	Tabs,
	Tab
} from '@material-ui/core'
import Amoconf from './../conf/Amoconf'
import Telegram from './../conf/Telegram'


// integrations routes
export default class Integra extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			tab: 0
		}
	}

	render(){
		return(
			<div>
				<AppBar position="static">
					<Tabs 
						value={this.state.tab} 
						onChange={(e, v) => this.setState({ tab: v })} 
					>
						<Tab label="АМО CRM" />
						<Tab label="Telegram" />
					</Tabs>
				</AppBar>
				<TabPanel 
					value={this.state.tab} 
					index={0}
				>
					<Amoconf />
				</TabPanel>
				<TabPanel 
					value={this.state.tab} 
					index={1}
				>
					<Telegram />
				</TabPanel>
			</div>
		)
	}
}

// tab panel content
function TabPanel(props){
	return props.index === props.value && props.children
}


