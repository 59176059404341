import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import store from './../../reducers/store'
import { createBrowserHistory } from "history"
import MomentUtils from '@date-io/moment'
import {
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/ru' 
import Entry from './Entry'

moment.locale('ru')

const history = createBrowserHistory()

// app component
export default class App extends React.Component {
	render(){
		return(
			<Provider store={store}>
				<Router history={history}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Entry />
					</MuiPickersUtilsProvider>
				</Router>
			</Provider>
			
		)
	}
}


