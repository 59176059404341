import React from 'react'
import { withRouter } from 'react-router-dom'
import { 
	get_query,
	delete_query
} from './../../functions'
import moment from 'moment'
import {
	CircularProgress,
	IconButton
} from '@material-ui/core'
import MUIDataTable from "mui-datatables"
import { table_options } from '../../constants/table'
import {
	Delete,
	Edit,  
	Visibility
} from '@material-ui/icons'
import PartclientsControl from './PartclientsControl'



// раздел клиенты сайта
class Partclients extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			data: [],
			paginator: {}
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true  })
		get_query('/api/clientlist')
		.then((res) => {
			this.setState({ data: res, loader: false  })
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
		})
	}

	deleteRow(id){
		this.setState({ loader: true  })
		delete_query(`/api/clients/${id}`)
		.then(() => {
			this.getData()
		})
		.catch((err) => {
			this.setState({ loader: false  })
		})
	}

	confCols(){
		let cols = [{
			name: "name",
			label: "Наименование",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "category",
			label: "Категория",
			options: {
				filter: false,
				sort: false
			}
		},{
			name: "country",
			label: "Страна",
			options: {
				filter: false,
				sort: false
			}
		},{
			name: "updated_at",
			label: "Обновлено",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? (
								<span>
									{moment(val, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')} 
								</span>
							) : false
						}
					</>
				)
			}
		},{
			name: 'id',
			label: 'Действия',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<div style={{ width: 180 }}>
						<IconButton
							onClick={() => this.props.history.push(`/partclients/view/${val}`)}
						>
							<Visibility />
						</IconButton>
						<IconButton
							color='primary'
							onClick={() => this.props.history.push(`/partclients/edit/${val}`)}
						>
							<Edit />
						</IconButton>
						<IconButton
							color='secondary'
							onClick={() => this.deleteRow(val)}
						>
							<Delete />
						</IconButton>
					</div>
				)
			}
		}]
		return cols
	}

	render(){
		return(
			<div>
				<PartclientsControl />
				<MUIDataTable
					title={this.state.loader ? <CircularProgress /> : "Клиенты Zig-Zag"}
					data={this.state.data}
					columns={this.confCols()}
					options={table_options}
				/>
			</div>
		)
	}
}

export default withRouter(Partclients)


