import React from 'react'
import PropTypes from 'prop-types'


// Exception page
export default class Exception extends React.Component {

	render(){
		return(
			<div className='fullpage'>
				<div className='fullpage-content'>
					<h1>{this.props.code}</h1>
				</div>
			</div>
		)
	}
}

Exception.propTypes = {
	code: PropTypes.number.isRequired
}


