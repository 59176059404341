import { useRef, useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import DownControl from '../../containers/modules/DownControl'
import {
	Button,
} from '@material-ui/core'


// Tiny MCE Editor
export function Mcedit(props) {
	const editorRef = useRef(null);
	const [dirty, setDirty] = useState(false);
	useEffect(() => setDirty(false), [props.initialValue]);
	const save = () => {
		if (editorRef.current) {
			const content = editorRef.current.getContent();
			setDirty(false);
			editorRef.current.setDirty(false);
			props.saveAction(content)
		}
	};
	return(
		<>
			<Editor 
				initialValue={props.initialValue}
				onInit={(evt, editor) => editorRef.current = editor}
				onDirty={() => setDirty(true)}
				init={{
					selector: 'textarea#format-hmtl5',
					height: 500,
					plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
					menubar: 'file edit view insert format tools table help',
					toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
					toolbar_sticky: true,
					toolbar_mode: 'wrap',
					file_picker_types: 'image',
					images_file_types: 'jpeg,jpg,jpe,png,gif,bmp',
					images_upload_base_path: `${process.env.REACT_APP_PROMO_API}`,
					images_upload_url: `${process.env.REACT_APP_PROMO_API}/api/testfile`,
					quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
				}}
			/>
			<DownControl
				loader={props.loader}
			>
				<Button
					variant='contained'
					color='secondary'
					onClick={() => props.goBack()}
				>
					Отменить
				</Button>
				<Button
					variant='contained'
					color='primary'
					onClick={() => save()}
				>
					Сохранить
				</Button>
			</DownControl>
		</>
	)
}


