import React from 'react'

// Part blog control panel
export default class UpControl extends React.Component {

	render(){
		return(
			<div className='row-control'>
				{this.props.children}
			</div>
		)
	}
}


