import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import {
	Typography,
	FormControl,
	TextField,
	FormControlLabel,
	Switch,
	Select,
	InputLabel,
	MenuItem,
	Grid
} from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers"
import { Mcedit } from './../../containers/modules/Mcedit'
import { post_query } from './../../functions'
import {
	layouts,
	menuitems
} from './../../constants'
import PagesInfo from './PagesInfo'

const initState = {
	name: '',
	engurl: '',
	layout: 'application',
	menuitem: 'item1',
	enqueue: 1,
	viewdate: moment(),
	viewrecord: false,
	content: ''
}

// new blog record
class PagesNew extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	UNSAFE_componentWillMount(){
		this.setState(Object.assign({}, this.state, initState))
	}

	// save record data
	saveData(content){
		this.setState({ loader: true })
		let data = {
			name: this.state.name,
			engurl: this.state.engurl,
			layout: this.state.layout,
			menuitem: this.state.menuitem,
			enqueue: this.state.enqueue,
			viewdate: this.state.viewdate.unix(),
			viewrecord: this.state.viewrecord,
			lastuser: this.props.lastuser,
			content: content
		}
		post_query('/api/pages/add', data)
		.then((res) => {
			this.goBack()
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	goBack(){
		this.props.history.push('/pages')
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Новая страница в основное меню
				</Typography>
				<Grid container>
					<Grid item xs={6}>
						<div className='fields'>
							<div>
								<FormControl
									className='form-item'
								>
									<TextField 
										inputProps={{style:{ width: '100%' }}}
										label='Название в пункте меню'
										value={this.state.name}
										onChange={(ev) => this.setState({ name: ev.target.value })}
									/>
								</FormControl>
							</div>
							<div>
								<FormControl
									className='form-item'
								>
									<TextField 
										inputProps={{style:{ width: '100%' }}}
										label='Eng URL страницы'
										value={this.state.engurl}
										onChange={(ev) => this.setState({ engurl: ev.target.value })}
									/>
								</FormControl>
							</div>
							<div>
								<FormControl
									className='form-item'
								>
									<InputLabel>Шаблон</InputLabel>
									<Select
										style={{ width: '100%' }}
										value={this.state.layout}
										onChange={(ev) => this.setState({ layout: ev.target.value })}
									>
										{
											layouts.map((l, id) => {
												return(
													<MenuItem key={id} value={l}>{l}</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
							</div>
							<div>
								<FormControl
									className='form-item'
								>
									<InputLabel>Идентификатор пункта меню</InputLabel>
									<Select
										style={{ width: '100%' }}
										value={this.state.menuitem}
										onChange={(ev) => this.setState({ menuitem: ev.target.value })}
									>
										{
											menuitems.map((m, id) => {
												return(
													<MenuItem key={id} value={m}>{m}</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
							</div>
							<div>
								<FormControl
									className='form-item'
								>
									<TextField 
										inputProps={{style:{ width: '100%' }}}
										label='Очередь в пункте меню'
										type='number'
										value={this.state.enqueue}
										onChange={(ev) => this.setState({ enqueue: ev.target.value })}
									/>
								</FormControl>
							</div>
							<div>
								<FormControl
									className='form-item'
								>
									<DatePicker
										openTo="year"
										format="DD-MM-YYYY"
										label="Начало действия"
										views={["date"]}
										value={this.state.viewdate}
										onChange={(viewdate) => this.setState({ viewdate: viewdate })}
									/>
								</FormControl>
							</div>
							<div>
								<FormControlLabel
									value='start'
									control={
										<Switch 
											color="primary" 
											checked={this.state.viewrecord}
											onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
										/>
									}
									label={this.state.viewrecord ? 
										<p style={{width: 120}}>Показано</p> : 
										<p style={{width: 120}}>Скрыто</p>
									}
									labelPlacement="start"
								/>
							</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<PagesInfo />
					</Grid>
				</Grid>
				<Mcedit
					token={this.props.token}
					loader={this.state.loader}
					initialValue={this.state.content}
					goBack={() => this.goBack()}
					saveAction={(c) => this.saveData(c)}
				/>
			</>
		)
	}
}

function mapStateToProps(state){
	return{
		lastuser: state.user_reducer.email,
		token: state.user_reducer.token
	}
}

export default withRouter(
	connect(mapStateToProps)(PagesNew)
)




