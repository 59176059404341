import React from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog, 
	DialogContent, 
	DialogTitle,
	DialogActions,
	TextField,
	CircularProgress
} from '@material-ui/core'
import { post_query } from './../../functions'
import { RoleField } from '../ui/fields'

const initState = {
	loader: false,
	email: '',
	fullname: '',
	role: 'manager',
	password: '',
	confirm: ''
}

// User add/edit dlg
export default class UserDlg extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(np.open && np.open !== this.props.open){
			if(Array.isArray(np.user)){
				this.setState({
					email: np.user[0],
					fullname: np.user[1],
					role: np.user[2],
					password: false,
					confirm: false
				})
			} else {
				this.setState(Object.assign({}, this.state, initState))
			}
		}	
	}

	// сохранить пользователя
	postUser(){
		let user = Object.assign({}, this.state)
		if(user.password === user.confirm){
			this.setState({ loader: true })
			post_query('/api/user', {
				email: user.email,
				fullname: user.fullname,
				role: user.role,
				password: user.password,
			})
			.then((res) => {
				this.setState({ loader: false })
				this.props.close()
				this.props.get_data()
			})
			.catch((err) => {
				this.setState({ loader: false })
			})
		} else {
			console.log('pswd not confirmed')
		}
	}

	render(){
		return(
			<Dialog
				open={this.props.open}
				onClose={() => this.props.close()}
			>
				<DialogTitle>Добавить/Редактировать пользователя</DialogTitle>
				<DialogContent>
					<div className='form-item'>
						<TextField 
							fullWidth
							label='Email'
							value={this.state.email}
							onChange={(ev) => this.setState({ email: ev.target.value })}
						/>
					</div>
					<div className='form-item'>
						<TextField 
							fullWidth
							label='Полное имя'
							value={this.state.fullname}
							onChange={(ev) => this.setState({ fullname: ev.target.value })}
						/>
					</div>
					<div className='form-item'>
						<RoleField 
							value={this.state.role}
							change={(ev) => this.setState({ role: ev })}
						/>
					</div>
					{
						typeof(this.state.password) === 'string' && (
							<div className='form-item'>
								<TextField 
									fullWidth
									label='Пароль'
									value={this.state.password}
									type='password'
									onChange={(ev) => this.setState({ password: ev.target.value })}
								/>
							</div>
						)
					}
					{
						typeof(this.state.confirm) === 'string' && (
							<div className='form-item'>
								<TextField 
									fullWidth
									label='Подтверждение пароля'
									value={this.state.confirm}
									type='password'
									onChange={(ev) => this.setState({ confirm: ev.target.value })}
								/>
							</div>
						)
					}
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						onClick={() => this.props.close()}
					>
						Отмена
					</Button>
					<Button
						disabled={this.state.loader}
						variant='contained'
						onClick={() => this.postUser()}
					>
						{
							this.state.loader ? (
								<CircularProgress size={24} />
							) : 'Сохранить'
						}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

UserDlg.propTypes = {
	close: PropTypes.func.isRequired,
	// open: PropTypes.isRequired
}


