import React from 'react'
import { withRouter } from 'react-router-dom'
import Approutes from './Approutes'
import Mainloader from '../single/Mainloader'
import { sso_query } from './../../functions'

// enrty point
class Enrty extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	componentDidMount(){
		this.entryQuery()
	}

	entryQuery(){
		this.setState({ loader: true })
		sso_query()
		.then(() => {
			this.setState({ loader: false })
		})
		.catch(() => {
			this.setState({ loader: false })
			this.props.history.push('/login')
		})
	}

	render(){
		return(
			<>
				{
					this.state.loader ? (
						<Mainloader />
					) : (
						<Approutes />
					)

				}
			</>
		)
	}
}

export default withRouter(Enrty)
