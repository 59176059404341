import React from 'react'
import {
	CircularProgress
} from '@material-ui/core'


// Main relative loader
export default class Mainloader extends React.Component {

	render(){
		return(
			<div className='fullpage'>
				<div className='fullpage-content'>
					<CircularProgress />
				</div>
			</div>
		)
	}
}


