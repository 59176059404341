import React from 'react'
import {
	CircularProgress
} from '@material-ui/core'


// Loader comp
export default class Loader extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<div 
				className='div-flex-row'
				style={{
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<CircularProgress size={24} />
			</div>
		)
	}
}


