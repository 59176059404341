import React from 'react'
import {
	Button,
	TextField,
	TextareaAutosize,
	CircularProgress,
	Grid,
	Typography
} from '@material-ui/core'
import { get_query, post_query } from '../../functions'
import { amo_conf } from '../../constants/conf'


const initState = {
	loader: false,
	redirect_uri: '',
	client_id: '',
	client_secret: '',
	code: '',
	refresh_token: ''
}

// Amo configuration
export default class Amoconf extends React.Component {
	constructor(props){
		super(props)
		this.state = {
		}
	}

	UNSAFE_componentWillMount(){
		this.setState(Object.assign({}, this.state, initState))
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		get_query('/api/amo/conf')
		.then((res) => {
			let obj = {}
			amo_conf.map((a) => {
				let conf = res.find(x => x.confname === a)
				if(conf){
					obj[a] = conf.confval
				}
			})
			obj.loader = false
			this.setState(Object.assign({}, this.state, obj))
		})
		.catch((err) => {
			this.setState(Object.assign({}, this.state, initState))
		})
	}

	updateAmo(){
		this.setState({ loader: true })
		post_query('/api/amo/update', {
			redirect_uri: this.state.redirect_uri,
			client_id: this.state.client_id,
			client_secret: this.state.client_secret,
			code: this.state.code
		})
		.then((res) => {
			let obj = {}
			amo_conf.map((a) => {
				let conf = res.find(x => x.confname === a)
				if(conf){
					obj[a] = conf.confval
				}
			})
			obj.loader = false
			this.setState(Object.assign({}, this.state, obj))
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<Grid container>
				<Grid item xs={8}>
					<Typography variant='h3'>
						Amo конфигурация
					</Typography>
					<div>
						<TextField
							fullWidth 
							disabled={this.state.loader}
							label='Redirect URI'
							value={this.state.redirect_uri}
							onChange={(ev) => this.setState({ redirect_uri: ev.target.value })}
						/>
					</div>
					<div>
						<TextField 
							fullWidth
							disabled={this.state.loader}
							label='client_id'
							value={this.state.client_id}
							onChange={(ev) => this.setState({ client_id: ev.target.value })}
						/>
					</div>
					<div>
						<TextField 
							fullWidth
							disabled={this.state.loader}
							label='clent_secret'
							value={this.state.client_secret}
							onChange={(ev) => this.setState({ client_secret: ev.target.value })}
						/>
					</div>
					<div>
						<p>Code</p>
						<TextareaAutosize
							style={{
								maxWidth: '100%',
								width: '100%',
								height: 150,
							}}
							disabled={this.state.loader}
							rowsMax={6}
							placeholder="code"
							value={this.state.code}
							onChange={(ev) => this.setState({ code: ev.target.value })}
						/>
					</div>
					<div>
						<p>Refresh token</p>
						<TextareaAutosize
							style={{
								maxWidth: '100%',
								width: '100%',
								height: 150,
							}}
							disabled
							rowsMax={6}
							placeholder="refresh_token"
							value={this.state.refresh_token}
						/>
					</div>
				</Grid>
				<Grid 
					item xs={4}
					style={{
						padding: 16
					}}
				>
					<p>
						После перезапуска/обновления приложения, ввести в поля соответствующие данные, и выполнить ОБНОВИТЬ.
						Данное действие авторизует приложение в АМО ЦРМ и запустит задачу обновления ключей каждые 5 часов.
						Ключи доступа дают возможность использовать АПИ АМО ЦРМ в приложении при внедрении соответствующего функционала
					</p>
					<p>
						При наличие ключа в поле refresh_token - система считается авторизованной
					</p>
					<div className='row-control-down'>
						<Button
							variant='contained'
							color='primary'
							onClick={() => this.updateAmo()}
						>
							{
								this.state.loader ? (
									<CircularProgress color='inherit' size={24} />
								) : 'Обновить'
							}
						</Button>
					</div>
				</Grid>
			</Grid>
		)
	}
}


