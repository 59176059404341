import React from 'react'
import { get_query, delete_query } from './../../functions'
import MUIDataTable from "mui-datatables"
import UsersControl from './UsersControl'
import { table_options } from '../../constants/table'
import { 
	IconButton,
	CircularProgress,
	Typography
} from '@material-ui/core'
import {
	Delete,
	Edit,  
} from '@material-ui/icons'
import UserDlg from '../../containers/modules/UserDlg'
import {UniConfirm} from './../../containers/ui'



// System users 
export default class Users extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			data: [],
			paginator: {},
			control_user: false
		}
	}

	componentDidMount(){
		this.getData()
	}
	// получить пользователей
	getData(){
		this.setState({ loader: true  })
		get_query('/api/users')
		.then((res) => {
			this.setState({ data: res, loader: false  })
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
		})
	}
	// удалить пользователя
	deleteUser(id){
		this.setState({ loader: true  })
		delete_query(`/api/user/${id}`)
		.then((res) => {
			this.getData()
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	// обновить пользователя
	putUser(){

	}

	confCols(){
		let cols = [{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "fullname",
			label: "Полное имя",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "role",
			label: "Роль",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "id",
			label: "Действия",
			options: {
				customBodyRender: (id, tableMeta) => (
					<>
						<IconButton
							color='primary'
							onClick={() => this.setState({ control_user: tableMeta.rowData })}
						>
							<Edit />
						</IconButton>
						<UniConfirm
							action={() => this.deleteUser(id)}
						>
							<IconButton
								color='secondary'
							>
								<Delete />
							</IconButton>
						</UniConfirm>
					</>
				)
			}
		}]
		return cols
	}

	render(){
		let loader = this.state.loader ? <CircularProgress /> : "Пользователи системы"
		return(
			<>
				<div>
					<Typography variant='h4'>
						Управление пользователями системы
					</Typography>
					<UsersControl 
						open_control={() => this.setState({ control_user: true })}
					/>
					<MUIDataTable
						title={loader}
						data={this.state.data}
						columns={this.confCols()}
						options={table_options}
					/>
				</div>
				<UserDlg 
					open={Boolean(this.state.control_user)}
					user={this.state.control_user}
					get_data={() => this.getData()}
					close={() => this.setState({ control_user: false })}
				/>
			</>
		)
	}
}


