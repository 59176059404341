import React from 'react'
import { 
	Typography 
} from '@material-ui/core'


// short instruction
export default class PagesInfo extends React.Component {

	render(){
		return(
			<>
				<Typography variant='h5'>
					Краткая инструкция
				</Typography>
				<div>
					<table width='100%' border='1'>
						<thead>
							<tr>
								<th>Пункт</th>
								<th>Краткое назначение</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Название в пункте меню</td>
								<td>
									Имя, которое будет отображаться в основном меню. Данный пункт меню, при нажатии, будет переадресовывать на страницу с текущим содержанием
								</td>
							</tr>
							<tr>
								<td>Eng URL страницы</td>
								<td>
									Английское наименование страницы, отображаемое в url. Вводится 1 английским словом без спецсимволов и пробелов с маленькой буквы. Например mobile.
								</td>
							</tr>
							<tr>
								<td>Шаблон</td>
								<td>Статический шаблон страницы, задаваемый программно. Допускается выбор из заранее подготовленных.</td>
							</tr>
							<tr>
								<td>Идентификатор пункта меню</td>
								<td>Пункт основного меню. На текущий момент их 6. Идентификаторы item1, item2 ... . Отсчет идет слева направо.</td>
							</tr>
							<tr>
								<td>Очередь в пункте меню</td>
								<td>Очередность вывода в выпадающем меню. Сначала идут статически/программно заданные страницы, затем по порядку создаваемые здесь.</td>
							</tr>
							<tr>
								<td>Начало действия</td>
								<td>Дата начала показа пункта меню и самой страницы</td>
							</tr>
							<tr>
								<td>Показано/Скрыто</td>
								<td>Показывать или скрывать страницу. (Не зависимо от даты начала действия)</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		)
	}
}


