import React from 'react'
import {
	Button
} from '@material-ui/core'


// Users control
export default class UsersControl extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<div className='row-control'>
				<Button
					variant='contained'
					onClick={() => this.props.open_control()}
				>
					Добавить пользователя
				</Button>
			</div>
		)
	}
}


