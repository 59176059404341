// conf variabled

// amo conf variables
export const amo_conf = [
    'redirect_uri',
    'client_id',
    'client_secret',
    'code',
    'refresh_token'
]

// application roles
export const roles = [{
    name: 'admin',
    label: 'Администратор'
},{
    name: 'manager',
    label: 'Менеджер'
}]

// lyouts
export const layouts = [
    'application',
    'mainslider',
    'reviewslider',
    'howslider1',
    'howslider2',
    'howslider3'
]

// menu itams id
export const menuitems = [
    'item1',
    'item2',
    'item3',
    'item4',
    'item5',
    'item6'
]