import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button,
	Typography,
	FormControl,
	TextField,
	TextareaAutosize,
	FormControlLabel,
	Switch
} from '@material-ui/core'
import ImageLoader from './../../containers/modules/ImageLoader'
import DownControl from '../../containers/modules/DownControl'
import { post_query } from './../../functions'

const initState = {
	name: '',
	viewrecord: false,
	image: '',
	description: ''
}

// new blog record
class BranchsolsNew extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	UNSAFE_componentWillMount(){
		this.setState(Object.assign({}, this.state, initState))
	}

	// save record data
	saveData(){
		this.setState({ loader: true })
		let data = {
			viewrecord: this.state.viewrecord,
			name: this.state.name,
			image: this.state.image,
			description: this.state.description
		}
		post_query('/api/branchsols/add', data)
		.then((res) => {
			this.props.history.push(`/branchsols`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Новая возможность
				</Typography>
				<div className='div-flex-row'>
					<div
						style={{ padding: 16 }}
					>
						<ImageLoader 
							width={300}
							height={300}
							img={this.state.image}
							upload={(l) => this.setState({ image: l })}
						/>
					</div>
					<div className='fields'>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									inputProps={{style:{ width: 700 }}}
									label='Категория'
									value={this.state.name}
									onChange={(ev) => this.setState({ name: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControlLabel
								value='start'
								control={
									<Switch 
										color="primary" 
										checked={this.state.viewrecord}
										onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
									/>
								}
								label={this.state.viewrecord ? 
									<p style={{width: 120}}>Показано</p> : 
									<p style={{width: 120}}>Скрыто</p>
								}
								labelPlacement="start"
							/>
						</div>
					</div>
				</div>
				<div>
					<p>Краткое описание категории:</p>
					<TextareaAutosize
						placeholder='Краткое описание категории'
						rowsMin={5}
						style={{ width: '100%' }}
						value={this.state.description}
						onChange={(ev) => this.setState({ description: ev.target.value })}
					/>
				</div>
				<DownControl
					loader={this.state.loader}
				>
					<Button
						variant='contained'
						color='secondary'
						onClick={() => this.props.history.push('/branchsols')}
					>
						Отменить
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.saveData()}
					>
						Сохранить
					</Button>
				</DownControl>
			</>
		)
	}
}

export default withRouter(BranchsolsNew)


