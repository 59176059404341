import React from 'react'
import PropTypes from 'prop-types'
import { uploadfile_query } from '../../functions'
import Loader from './Loader'


// image loader comp
export default class ImageLoader extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			selectedFile: false
		}
	}

	changeHandler(ev){
		if(ev.target.files[0]){
			this.setState({ selectedFile: ev.target.files[0] })
		}
	}

	handleSubmission(){
		this.setState({ loader: true })
		const formData = new FormData()
		formData.append('file', this.state.selectedFile)
		uploadfile_query('/api/addfile', formData)
		.then((res) => {
			this.props.upload(res.location)
			this.setState({ loader: false })
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}
	

	render(){
		return(
			<div>
				<div 
					className='selected-file'
					style={{
						width: this.props.width,
						height: this.props.height
					}}
				>
					{
						this.state.loader ? (
							<Loader />
						) : (
							<img src={`${process.env.REACT_APP_PROMO_API}${this.props.img}`} alt={this.props.img}/>
						)
					}
				</div>
				{
					!this.props.hidebtn ? (
						<>
							<div className='selected-input'>
								<input type="file" name="file" onChange={(ev) => this.changeHandler(ev)} />
							</div>
							<button onClick={() => this.handleSubmission()}>Upload</button>
						</>
					) : false
				}
				
			</div>
		)
	}
}

ImageLoader.defaultProps = {
	width: 220,
	height: 96,
	hidebtn: false
}

ImageLoader.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	hidebtn: PropTypes.bool
}
