//  queries
import axios from 'axios'
import store from './../reducers/store'
import { notify } from './../containers/ui'

// GET query
export function get_query(url, data){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(`${process.env.REACT_APP_PROMO_API}${url}`, {
            params: data,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        })
        .then((res) => {
            resolve(res.data.data)
        })
        .catch((err) => {
            handle_errors(err)
            reject()
        })
    })
}

// DELETE query
export function delete_query(url, data){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.delete(`${process.env.REACT_APP_PROMO_API}${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        })
        .then((res) => {
            resolve(res.data.data)
        })
        .catch((err) => {
            handle_errors(err)
            reject()
        })
    })
}

// POST query
export function post_query(url, data){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.post(`${process.env.REACT_APP_PROMO_API}${url}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        })
        .then((res) => {
            resolve(res.data.data)
        })
        .catch((err) => {
            handle_errors(err)
            reject()
        })
    })
}

// загрузка файла
export function uploadfile_query(url, file){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        fetch(`${process.env.REACT_APP_PROMO_API}${url}`, {
            method: 'POST',
            body: file,
            headers: new Headers({
                'Authorization': token,
            })
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            resolve(data)
        })
        .catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

// handle errors
export function handle_errors(err){
    if(err.response){
        switch(err.response.status){
            case 200:
                return
            case 400:
                notify.error('Ошбика передачи параметров запроса')
                return
            case 401:
                notify.error('Ошибка авторизации')
                return
            case 403:
                notify.error('Отсутствуют права доступа')
                return
            case 500:
                notify.error('Ошибка обработки запроса')
                return
            default:
                notify.error('Ошибка обработки запроса')
                return
        }
    } else {
        notify.error('Ошибка связи с сервером')
    }
}