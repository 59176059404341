export function setEmail(payload){
    return { type: 'SET_EMAIL', payload }
}
export function setFullname(payload){
    return { type: 'SET_FULLNAME', payload }
}
export function setRole(payload){
    return { type: 'SET_EMAIL', payload }
}
export function setToken(payload){
    if(payload){
        localStorage.setItem('zigzag_token', payload)
    } else {
        localStorage.removeItem('zigzag_token')
    }
    return { type: 'SET_TOKEN', payload }
}
export function setUserInit(payload){
    localStorage.setItem('zigzag_token', payload.token)
    return { type: 'SET_USER_INIT', payload }
}
export function setUserClear(payload){
    localStorage.removeItem('zigzag_token')
    return { type: 'SET_USER_INIT', payload }
}