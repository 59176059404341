import React from 'react'
import { withRouter } from 'react-router-dom'
import { 
	get_query,
	delete_query
} from './../../functions'
import MUIDataTable from "mui-datatables"
import { table_options } from '../../constants/table'
import moment from 'moment'
import { 
	CircularProgress, 
	IconButton,
	Button,
	Typography
} from '@material-ui/core'
import {
	Delete,
	Edit,  
	Check,
	Close
} from '@material-ui/icons'
import UpControl from './../../containers/modules/UpControl'
import { UniConfirm } from './../../containers/ui'


// zig-zag pages comp
class Pages extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			data: [],
			paginator: {}
		}
	}

	componentDidMount(){
		this.getData()
	}
	
	// 	получить данные
	getData(){
		this.setState({ loader: true  })
		get_query('/api/pages')
		.then((res) => {
			this.setState({ data: res, loader: false  })
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
		})
	}

	//  удалить данные
	deleteRow(id){
		this.setState({ loader: true  })
		delete_query(`/api/pages/${id}`)
		.then(() => {
			this.getData()
		})
		.catch((err) => {
			this.setState({ loader: false  })
		})
	}

	// конфигурация
	confCols(){
		let cols = [{
			name: "name",
			label: "Название пункта меню",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "engurl",
			label: "Eng URL",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "menuitem",
			label: "ИД меню",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "enqueue",
			label: "Очередь в меню",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "layout",
			label: "Шаблон",
			options: {
				filter: false,
				sort: false,
			}
		},{
			name: "updated_at",
			label: "Дата обновления",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? (
								<span>
									{moment(val, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')} 
								</span>
							) : false
						}
					</>
				)
			}
		},{
			name: "viewdate",
			label: "Дата начала показа",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? (
								<span>
									{moment(val, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')} 
								</span>
							) : false
						}
					</>
				)
			}
		},{
			name: "viewrecord",
			label: "Показывать",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<>
						{
							val ? 
								<Check style={{ color: 'green' }}/> :
								<Close style={{ color: 'red' }}/>
								
						}
					</>
				)
			}
		},{
			name: 'id',
			label: 'Действия',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (val) => (
					<div style={{ width: 180 }}>
						<IconButton
							color='primary'
							onClick={() => this.props.history.push(`/pages/edit/${val}`)}
						>
							<Edit />
						</IconButton>
						<UniConfirm
							action={() => this.deleteRow(val)}
						>
							<IconButton
								color='secondary'
							>
								<Delete />
							</IconButton>
						</UniConfirm>
					</div>
				)
			}
		}]
		return cols
	}

	render(){
		return(
			<div>
				<Typography variant='h4'>
					Раздел динамических страниц, привязанных к меню основного шаблона
				</Typography>
				<UpControl>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.history.push('/pages/new')}
					>
						Добавить страницу
					</Button>
				</UpControl>
				<MUIDataTable
					title={this.state.loader ? <CircularProgress /> : "Страницы меню Zig-Zag"}
					data={this.state.data}
					columns={this.confCols()}
					options={table_options}
				/>
			</div>
		)
	}
}

export default withRouter(Pages)

