import React from 'react'
import {
	withRouter
} from 'react-router-dom'
import { 
	Button,
	CircularProgress,
	TextField 
} from '@material-ui/core'
import { LogoImg } from './../../styles/imgs'
import { auth_query } from '../../functions/auth'



// login single page
class Login extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			error: false,
			loader: false,
			email: '',
			password: ''
		}
	}

	loginQuery(){
		this.setState({ loader: true, error: false })
		auth_query(this.state.email, this.state.password)
		.then(() => {
			this.props.history.push('/')
		})
		.catch((err) => {
			this.setState({ 
				loader: false,
				error: 'Ошибка авторизации'
			})
		})
	}

	render(){
		return(
			<div className='fullpage'>
				<div className='fullpage-content'>
					<div className='form-item'>
						<LogoImg 
							style={{
								width: 170,
								height: 80
							}}
						/>
					</div>
					<p className='txt-alert'>
						{this.state.error}
					</p>
					<div className='form-item'>
						<TextField 
							label='E-mail'
							value={this.state.value}
							onChange={(ev) => this.setState({ email: ev.target.value })}
						/>
					</div>
					<div className='form-item'>
						<TextField 
							label='Password'
							type='password'
							value={this.state.password}
							onChange={(ev) => this.setState({ password: ev.target.value })}
						/>
					</div>
					<div className='txt-center form-item'>
						<Button
							variant='contained'
							color='primary'
							onClick={() => this.loginQuery()}
						>
							{
								this.state.loader ? <CircularProgress color='inherit' size={24} /> : 'Вход'
							}
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Login)


