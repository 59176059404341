import React from 'react'
import { connect } from 'react-redux'
import { 
	Redirect,
	Route, 
	withRouter
} from 'react-router-dom'
import Exception from './Exception'
import Layout from './../main/Layout'


// private routes containers
class PrivateRoute extends React.Component {

	checkLocalAuth(){
		if(this.props.allowed){
			if(this.props.allowed === '*'){
				return true
			} else {
				if(this.props.alowed.indexOf(this.props.role) !== -1){
					return true
				} else {
					return false
				}
			}
		} else {
			return true
		}
	}

	render(){
		return(
			<>
				{
					this.props.token ? (
						<>
							{
								this.checkLocalAuth() ? (
									<Route {...this.props}>
										<Layout>
											{this.props.children}
										</Layout>
									</Route>
								) : (
									<Exception code={403} />
								)
							}
						</>
					) : (
						<Redirect 
							to={{
								pathname: '/login'
							}}
						/>
					)
					
				}
				
			</>
		)
	}
}

function mapStateToProps(state){
	return{
		token: state.user_reducer.token,
		role: state.user_reducer.role
	}
}

export default withRouter(
	connect(mapStateToProps)(PrivateRoute)
)


