import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import App from './components/main/App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <App />,
  	document.getElementById('root')
)
reportWebVitals()
