import React from 'react'
import PropTypes from 'prop-types'
import { 
    Button, 
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogActions 
} from '@material-ui/core'

// confirm dialog
export class UniConfirm extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            open: false
        }
    }
    render(){
        let t = this
        return(
            <>
                {
                    React.cloneElement(this.props.children, {
                        onClick: function(){ t.setState({ open: true }) }
                    })
                }
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                >
                    <DialogTitle>
                        {this.props.title}
                    </DialogTitle>
                    <DialogContent>
                        {this.props.content}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            onClick={() => this.setState({ open: false })}
                        >
                            Отмена
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => this.setState({ open: false }, () => this.props.action())}
                        >
                            Выполнить
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

UniConfirm.defaultProps = {
    title: 'Подтверждение',
    content: 'Вы действительно хотите выполнить действие'
}
UniConfirm.propTypes = {
    action: PropTypes.func.isRequired
}