import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	FormControl,
	TextField,
	Typography,
	FormControlLabel,
	Switch
} from '@material-ui/core'
import { 
	get_query,
	post_query 
} from './../../functions'
import { Mcedit } from '../../containers/modules/Mcedit'


// new trouble
class TroublesEdit extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			id: false,
			loader: false,
			label: '',
			viewrecord: false,
			description: ''
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		let id = this.props.match.params.id
		get_query(`/api/troubles/${id}`)
		.then((res) => {
			res.loader = false
			this.setState(Object.assign({}, this.state, res))
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	saveData(description){
		this.setState({ loader: true })
		let data = {
			id: this.state.id,
			label: this.state.label,
			viewrecord: this.state.viewrecord,
			description: description
		}
		post_query('/api/troubles/update', data)
		.then((res) => {
			this.props.history.push(`/troubles`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	goBack(){
		this.props.history.push('/troubles')
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Новая пробелма в модуль сайта
				</Typography>
				<div>
					<div>
						<FormControl
							className='form-item'
						>
							<TextField
								disabled={this.state.loader} 
								inputProps={{style:{ width: 700 }}}
								label='Тема проблемы'
								value={this.state.label}
								onChange={(ev) => this.setState({ label: ev.target.value })}
							/>
						</FormControl>
					</div>
					<div>
						<FormControlLabel
							value='start'
							control={
								<Switch
									disabled={this.state.loader} 
									color="primary" 
									checked={this.state.viewrecord}
									onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
								/>
							}
							label={this.state.viewrecord ? 
								<p style={{width: 120}}>Показано</p> : 
								<p style={{width: 120}}>Скрыто</p>
							}
							labelPlacement="start"
						/>
					</div>
				</div>
				<Mcedit
					loader={this.state.loader}
					initialValue={this.state.description}
					goBack={() => this.goBack()}
					saveAction={(c) => this.saveData(c)}
				/>
			</>
		)
	}
}

export default withRouter(TroublesEdit)


