import React from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core'
import { roles } from './../../constants'


// Role field
export class RoleField extends React.Component{
    render(){
        return(
            <FormControl
                fullWidth
            >
                <InputLabel>Роль</InputLabel>
                <Select
                    fullWidth
                    value={this.props.value}
                    onChange={(ev) => this.props.change(ev.target.value)}
                >
                    {
                        roles.map((r, id) => {
                            return(
                                <MenuItem key={id} value={r.name}>
                                    {r.label}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        )
    }
}