// menu const
import React from 'react'
import { 
    DesktopWindows,
    PersonOutline,
    EmojiEvents,
    ImportContacts,
    AspectRatio,
    Storage,
    Web,
    ChromeReaderMode,
    ErrorOutline,
    Dns,
} from '@material-ui/icons'


export const mainmenu = [{
    name: 'Dashboard',
    link: '/',
    icon: <DesktopWindows />
},{
    name: 'Пользователи',
    link: '/users',
    icon: <PersonOutline />
},{
    name: 'Страницы ZZ',
    link: '/pages',
    icon: <ImportContacts />
},{
    name: 'Проблемы ZZ',
    link: '/troubles',
    icon: <ErrorOutline />
},{
    name: 'Блог ZZ',
    link: '/blog',
    icon: <ChromeReaderMode />
},{
    name: 'Клиенты ZZ',
    link: '/partclients',
    icon: <Web />
},{
    name: 'Решения ZZ',
    link: '/branchsols',
    icon: <AspectRatio />
},{
    name: 'Возможности ZZ',
    link: '/opportunes',
    icon: <EmojiEvents />
},{
    name: 'База знаний',
    link: '/knowledge',
    icon: <Storage />
},{
    name: 'Интеграции',
    link: '/integra',
    icon: <Dns />
}]