import React from 'react'


// main application panel
export default class Panel extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<p>panel</p>
		)
	}
}


