import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import {
	Typography,
	FormControl,
	TextField,
	TextareaAutosize,
	FormControlLabel,
	Switch
} from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers"
import ImageLoader from './../../containers/modules/ImageLoader'
import { Mcedit } from '../../containers/modules/Mcedit'
import { post_query } from './../../functions'

const initState = {
	subject: '',
	viewdate: moment(),
	viewrecord: false,
	preview: '',
	preimg: '',
	content: ''
}

// new blog record
class BlogNew extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	UNSAFE_componentWillMount(){
		this.setState(Object.assign({}, this.state, initState))
	}

	// save record data
	saveData(content){
		this.setState({ loader: true })
		let data = {
			viewrecord: this.state.viewrecord,
			subject: this.state.subject,
			viewdate: this.state.viewdate.unix(),
			preview: this.state.preview,
			preimg: this.state.preimg,
			content: content
		}
		post_query('/api/blogs/add', data)
		.then((res) => {
			this.props.history.push(`/blog`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	goBack(){
		this.props.history.push('/blog')
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Новая статья в блог
				</Typography>
				<div className='div-flex-row'>
					<div
						style={{ padding: 16 }}
					>
						<ImageLoader 
							width={300}
							height={300}
							img={this.state.preimg}
							upload={(l) => this.setState({ preimg: l })}
						/>
					</div>
					<div className='fields'>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									inputProps={{style:{ width: 700 }}}
									label='Тема статьи'
									value={this.state.subject}
									onChange={(ev) => this.setState({ subject: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<DatePicker
									openTo="year"
									format="DD-MM-YYYY"
									label="Начало действия"
									views={["date"]}
									value={this.state.viewdate}
									onChange={(viewdate) => this.setState({ viewdate: viewdate })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextareaAutosize
									placeholder='Краткое описание'
									rowsMin={8}
									style={{ width: 700 }}
									label='Краткое описание'
									value={this.state.preview}
									onChange={(ev) => this.setState({ preview: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControlLabel
								value='start'
								control={
									<Switch 
										color="primary" 
										checked={this.state.viewrecord}
										onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
									/>
								}
								label={this.state.viewrecord ? 
									<p style={{width: 120}}>Показано</p> : 
									<p style={{width: 120}}>Скрыто</p>
								}
								labelPlacement="start"
							/>
						</div>
					</div>
				</div>
				<Mcedit
					loader={this.state.loader}
					initialValue={this.state.content}
					goBack={() => this.goBack()}
					saveAction={(c) => this.saveData(c)}
				/>
			</>
		)
	}
}

export default withRouter(BlogNew)


