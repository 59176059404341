import React from 'react'
import Loader from './Loader'


// Part blog down control panel
export default class DownControl extends React.Component {

	render(){
		return(
			<div className='row-control-down'>
				{
					this.props.loader ? (
						<Loader />
					) : this.props.children
				}
			</div>
		)
	}
}


