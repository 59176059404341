import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	FormControl,
	TextField,
	Typography,
	FormControlLabel,
	Switch
} from '@material-ui/core'
import { post_query } from './../../functions'
import { Mcedit } from '../../containers/modules/Mcedit'


// new itemsols
class ItemsolsNew extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			name: '',
			engurl: '',
			viewrecord: false,
			content: ''
		}
	}

	saveData(content){
		this.setState({ loader: true })
		let bs = this.props.match.params.bs
		let data = {
			id: bs,
			name: this.state.name,
			engurl: this.state.engurl,
			viewrecord: this.state.viewrecord,
			content: content
		}
		post_query('/api/itemsols/add', data)
		.then((res) => {
			this.props.history.push(`/branchsols/edit/${bs}`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	goBack(){
		let bs = this.props.match.params.bs
		this.props.history.push(`/branchsols/edit/${bs}`)
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Новое решение в категорию решений отрасли
				</Typography>
				<div>
					<div>
						<FormControl
							className='form-item'
						>
							<TextField 
								inputProps={{style:{ width: 700 }}}
								label='Решение'
								value={this.state.name}
								onChange={(ev) => this.setState({ name: ev.target.value })}
							/>
						</FormControl>
					</div>
					<div>
						<FormControl
							className='form-item'
						>
							<TextField 
								inputProps={{style:{ width: 700 }}}
								label='Eng URL идентификатор'
								value={this.state.engurl}
								onChange={(ev) => this.setState({ engurl: ev.target.value })}
							/>
						</FormControl>
					</div>
					<div>
						<FormControlLabel
							value='start'
							control={
								<Switch 
									color="primary" 
									checked={this.state.viewrecord}
									onChange={(ev) => this.setState({ viewrecord: ev.target.checked })}
								/>
							}
							label={this.state.viewrecord ? 
								<p style={{width: 120}}>Показано</p> : 
								<p style={{width: 120}}>Скрыто</p>
							}
							labelPlacement="start"
						/>
					</div>
				</div>
				<Mcedit
					loader={this.state.loader}
					initialValue={this.state.content}
					goBack={() => this.goBack()}
					saveAction={(c) => this.saveData(c)}
				/>
			</>
		)
	}
}

export default withRouter(ItemsolsNew)


