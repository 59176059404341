import React from 'react'
import { connect } from 'react-redux'
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Button,
	Drawer
} from '@material-ui/core'
import {
	Menu
} from '@material-ui/icons'
import Mainmenu from './Mainmenu'

// Appbar component
class Appbar extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: false
		}
	}

	render(){
		return(
			<div className='appbar-container'>
				<AppBar position="static">
					<Toolbar>
						<IconButton 
							edge="start" 
							className='appbar-btn'
							color="inherit" 
							aria-label="menu"
							onClick={() => this.setState({ open: true })}
						>
							<Menu />
						</IconButton>
						<Typography 
							variant="h6" 
							className='appbar-title'
						>
							Zig-Zag admin
						</Typography>
						<Button color='inherit'>
							{this.props.fullname}
						</Button>
					</Toolbar>
				</AppBar>
				<Drawer 
					anchor='left' 
					open={this.state.open} 
					onClose={() => this.setState({ open: false })}
				>
            		<Mainmenu 
						close={() => this.setState({ open: false })}
					/>
          		</Drawer>
			</div>
		)
	}
}

function mapStateToProps(state){
	return{
		fullname: state.user_reducer.fullname
	}
}

export default connect(mapStateToProps)(Appbar)
