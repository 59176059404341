const initialState = {
    email: false,
    fullname: false,
    role: false,
    token: localStorage.getItem('zigzag_token')
}
  
export function user_reducer(state = initialState, action) {
    switch(action.type){
        case 'SET_EMAIL':
            return Object.assign({}, state, {
                email: action.payload
            })
        case 'SET_FULLNAME':
            return Object.assign({}, state, {
                fullname: action.payload
            })
        case 'SET_ROLE':
            return Object.assign({}, state, {
                role: action.payload
            })
        case 'SET_TOKEN':
            return Object.assign({}, state, {
                token: action.payload
            })
        case 'SET_USER_INIT':
            return Object.assign({}, state, action.payload)
        case 'SET_USER_CLEAR':
            return Object.assign({}, initialState)
        default:
            return state
    }
};