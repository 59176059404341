import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Typography,
	FormControl,
	TextField,
	TextareaAutosize,
	Button,
	CircularProgress,
} from '@material-ui/core'
import { get_query, post_query } from './../../functions'
import ImageLoader from './../../containers/modules/ImageLoader'
import DownControl from '../../containers/modules/DownControl'


// Edit clients
class PartclientsEdit extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false
		}
	}

	componentDidMount(){
		this.getData()
	}

	saveRecord(){
		this.setState({ loader: true })
		let data = {
			id: this.state.id,
			name: this.state.name,
			country: this.state.country,
			category: this.state.category,
			link: this.state.link,
			description: this.state.description,
			img: this.state.img
		}
		post_query('/api/clients/update', data)
		.then((res) => {
			this.props.history.push(`/partclients/view/${res.id}`)
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	getData(){
		this.setState({ loader: true })
		let id = this.props.match.params.id
		get_query(`/api/clients/${id}`)
		.then((res) => {
			res.loader = false
			this.setState(Object.assign({}, this.state, res))
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<>
				<Typography variant='h4'>
					Редактировать клиента в раздел клиентов на сайт
				</Typography>
				<div className='div-flex-row'>
					<div
						style={{ padding: 16 }}
					>
						<ImageLoader 
							width={380}
							height={200}
							img={this.state.img}
							upload={(l) => this.setState({ img: l })}
						/>
					</div>
					<div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									disabled={this.state.loader}
									inputProps={{style:{ width: 700 }}}
									label='Наименование'
									InputLabelProps={{ shrink: Boolean(this.state.name) }}
									value={this.state.name}
									onChange={(ev) => this.setState({ name: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									disabled={this.state.loader}
									inputProps={{style:{ width: 700 }}}
									label='Категория'
									InputLabelProps={{ shrink: Boolean(this.state.category) }}
									value={this.state.category}
									onChange={(ev) => this.setState({ category: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									disabled={this.state.loader}
									inputProps={{style:{ width: 700 }}}
									label='Страна'
									InputLabelProps={{ shrink: Boolean(this.state.country) }}
									value={this.state.country}
									onChange={(ev) => this.setState({ country: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextField 
									disabled={this.state.loader}
									inputProps={{style:{ width: 700 }}}
									label='Ссылка'
									InputLabelProps={{ shrink: Boolean(this.state.link) }}
									value={this.state.link}
									onChange={(ev) => this.setState({ link: ev.target.value })}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl
								className='form-item'
							>
								<TextareaAutosize
									disabled={this.state.loader}
									placeholder='Краткое описание'
									rowsMin={5}
									style={{ width: 700 }}
									label='Краткое описание'
									value={this.state.description}
									onChange={(ev) => this.setState({ description: ev.target.value })}
								/>
							</FormControl>
						</div>
					</div>
				</div>
				<DownControl
					loader={this.state.loader}
				>
					<Button
						variant='contained'
						onClick={() => this.props.history.push('/partclients')}
					>
						Отмена
					</Button>
					<Button
						disabled={this.state.loader}
						variant='contained'
						color='primary'
						onClick={() => this.saveRecord()}
					>
						{
							this.state.loader ? (
								<CircularProgress color='inherit' size={24} />
							) : 'Сохранить'
						}
						
					</Button>
				</DownControl>
			</>
		)
	}
}

export default withRouter(PartclientsEdit)


