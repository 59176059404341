import React from 'react'
import {
	CircularProgress,
	TextareaAutosize,
	Typography,
	Button
} from '@material-ui/core'
import { 
	get_query, 
	post_query 
} from '../../functions'


// Telegram conf
export default class Telegram extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			data: {},
			testmsg: ''
		}
	}

	componentDidMount(){
		this.getData()
	}

	// получить данные 
	getData(){
		this.setState({ loader: true })
		get_query('/api/amo/telegram')
		.then((res) => {
			this.setState({ data: res, loader: false })
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	// отправить тестовое сообщение
	sendTest(){
		this.setState({ loader: true })
		post_query('/api/amo/sendtlg', {
			tlg: this.state.testmsg
		})
		.then((res) => {
			this.setState({ testmsg: '', loader: false })
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	render(){
		return(
			<div>
				<Typography
					variant='h3'
				>
					Telegram config
				</Typography>
				<div
					style={{
						padding: 16,
						height: 200,
						border: '1px solid rgba(0,0,0,0.4)'
					}}
				>
					{
						this.state.loader ? (
							<CircularProgress />
						) : (
							<TelegramConf 
								data={this.state.data}
							/>
						)
					}
				</div>
				<div>
					<Typography
						variant='h4'
					>
						Отправка тестового сообщения
					</Typography>
					<TextareaAutosize
						style={{
							maxWidth: '100%',
							width: '100%',
							height: 150,
						}}
						disabled={this.state.loader}
						rowsMax={6}
						placeholder="test msg..."
						value={this.state.testmsg}
						onChange={(ev) => this.setState({ testmsg: ev.target.value })}
					/>
					<div>
						<Button
							disabled={this.state.loader}
							variant='contained'
							onClick={() => this.sendTest()}
						>
							{
								this.state.loader ? (
									<CircularProgress color='inherit' size={24} />
								) : 'Отправить'
							}
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

// view telegram conf
function TelegramConf(props){
	return(
		<>
			{
				Object.keys(props.data).map((o, id) => {
					return(
						<p key={id}>
							<b>{o}:</b>&emsp;
							{props.data[o]}
						</p>
					)
				})
			}
		</>
	)
}


